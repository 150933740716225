export function generateRoute(url, params, designator = ':') {
    Object.keys(params).forEach(function (key) {
        url.replace(designator + key, params[key])
    })

    return url
}

function isObject(obj) {
    return obj && typeof obj === 'object' && obj.constructor === Object
}

export function clearStorage(docKey, document) {
    let preventDeletion = ['STATSIG_LOCAL_STORAGE_STABLE_ID', 'CUSTOM_AB_STABLE_ID', 'single_purchase_edits']
    let retained = {}
    for (var element of preventDeletion) {
        let value = localStorage.getItem(element)
        if (value != null) {
            retained[element] = value
        }
    }

    localStorage.clear()

    for (const [key, value] of Object.entries(retained)) {
        localStorage.setItem(key, value)
    }

    for (const [key, value] of Object.entries(retained)) {
        localStorage.setItem(key, value)
    }

    if (document && !document.id) {
        localStorage.setItem(docKey, isObject(document) ? JSON.stringify(document) : document)
    }
}

export function fixUnmatchedOpeningTags(inputString) {
    const unclosedTags = []
    const regex = /<(\w+)[^>]*>/g
    let match

    while ((match = regex.exec(inputString)) !== null) {
        const tagName = match[1]
        unclosedTags.push(tagName)
    }

    const closingTags = unclosedTags.map((tagName) => `</${tagName}>`).reverse()
    const fixedString = inputString + closingTags.join('')

    return fixedString
}

export function fixUnmatchedClosingTags(inputString) {
    const unmatchedClosingTags = []
    const regex = /<\/(\w+)[^>]*>/g
    let match
    let checkString = inputString
    while ((match = regex.exec(inputString)) !== null) {
        const tagName = match[1]
        const openingTag = `<${tagName}>`
        if (!checkString.includes(openingTag)) {
            // If the opening tag is not found in the input string, add it to unmatchedClosingTags
            unmatchedClosingTags.push(openingTag)
        } else {
            checkString = checkString.replace(new RegExp(openingTag), '')
        }
    }

    // Join the unmatched opening tags and the input string
    const fixedString = unmatchedClosingTags.reverse().join('') + inputString

    return fixedString
}

export function filenameFromTitle(titleContent, entityType) {
    return titleContent.replace(/<[^>]+>/g, '').trim() != ''
        ? titleContent.replace(/<[^>]+>/g, '').trim()
        : '[Untitled ' + entityType.replace(/^\w/, (c) => c.toUpperCase()) + ']'
}

export function itemHasContent(item) {
    return (item.term && item.term.trim().length) || (item.term_image && item.term_image.trim().length)
}
